//INFO: 연구실 리스트 화면
<script>
import { Page, Sort, GridComponent, ColumnsDirective, Toolbar, CommandColumn, Search } from '@syncfusion/ej2-vue-grids'
import { getLabList } from '@/api/admin/labs.js'
import { ref } from 'vue'
import AddBtn from '@/components/ui/AddBtn.vue' // 등록
import Dropdown from '@/components/ui/Dropdown.vue'

const isLoading = ref(false)

export default {
  name: 'LabList',
  components: {
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    AddBtn,
    Dropdown
  },
  provide: {
    grid: [Page, Sort, Toolbar, CommandColumn, Search]
  },
  data() {
    return {
      currentPath: this.$route.fullPath, // 현재 접속 경로
      pageSettings: { pageSize: 10 }, // 10개씩 잘라서 페이지네이션
      format: { type: 'dateTime', format: 'y/M/d' },
      toolbar: ['Search'],
      commands: [
        { buttonOption: { content: '연구실정보', cssClass: 'primary-btn' } },
        // TODO: 과제, 논문, 지재권 클릭 시 연구실 번호로 프로젝트 게시판 연결
        { buttonOption: { content: '과제', cssClass: 'btn-info' } },
        { buttonOption: { content: '논문', cssClass: 'btn-success' } },
        { buttonOption: { content: '지재권', cssClass: 'btn-warning' } },
        { buttonOption: { content: '성과', cssClass: 'btn-dark' } }
      ],
      listData: [],
      persistedData: [],
      searchConditions: [
        { value: 'labNo', text: '연구실번호' },
        { value: 'managerUserId', text: '관리자ID' },
        { value: 'labName', text: '연구실이름' },
        { value: 'leadResearcherName', text: '책임연구자' },
        { value: 'affiliatedInstitutionName', text: '대학교' }
      ],
      searchBy: ''
    }
  },

  mounted() {
    this.setSearchOptions()
    const getList = async () => {
      isLoading.value = true

      const data = (await getLabList()).items

      this.listData = data
        .map((item) => {
          return {
            labNo: item.labNo,
            labName: item.labName,
            leadResearcherName: item.leadResearcherName,
            managerUserId: item.managerUserId,
            updatedAt: item.updatedAt
          }
        })
        .sort((a, b) => {
          if (a.labNo > b.labNo) return 1
          return -1
        })

      this.persistedData = this.listData
      isLoading.value = false
    }
    getList()
  },

  methods: {
    commandClick(e) {
      if (e.target.classList.contains('primary-btn')) return this.labDetail(e)
      if (e.target.classList.contains('btn-info')) return this.labProjectContent(e)
      if (e.target.classList.contains('btn-success')) return this.labPaperContent(e)
      if (e.target.classList.contains('btn-warning')) return this.labPatentContent(e)
      if (e.target.classList.contains('btn-dark')) return this.labResultContent(e)
    },
    // 연구실 등록
    addLab() {
      this.$router.push({ path: `${this.currentPath}/w` })
    },
    // 연구실 정보
    labDetail(e) {
      this.$router.push({ path: `${this.currentPath}/u/${e.rowData.labNo}` })
    },
    // 연구실 번호에 맞는 과제 목록 이동
    labProjectContent(e) {
      // 연구실 번호
      const labNo = e.rowData.labNo
      this.$store.dispatch('searchStateStore/setLabNo', labNo)
      this.$store.dispatch('searchStateStore/setBoard', 'project')
      this.$router.push({ path: `/admin/board/project/${labNo}` })
    },
    // 연구실 번호에 맞는 논문 목록 이동
    labPaperContent(e) {
      // 연구실 번호
      const labNo = e.rowData.labNo
      this.$store.dispatch('searchStateStore/setLabNo', labNo)
      this.$store.dispatch('searchStateStore/setBoard', 'paper')
      this.$router.push({ path: `/admin/board/paper/${labNo}` })
    },
    // 연구실 번호에 맞는 지재권 목록 이동
    labPatentContent(e) {
      // 연구실 번호
      const labNo = e.rowData.labNo
      this.$store.dispatch('searchStateStore/setLabNo', labNo)
      this.$store.dispatch('searchStateStore/setBoard', 'patent')
      this.$router.push({ path: `/admin/board/patent/${labNo}` })
    },
    // 연구실 번호에 맞는 기술 이전 목록 이동
    labResultContent(e) {
      // 연구실 번호
      const labNo = e.rowData.labNo
      this.$store.dispatch('searchStateStore/setLabNo', labNo)
      this.$store.dispatch('searchStateStore/setBoard', 'result')
      this.$router.push({ path: `/admin/board/result/${labNo}` })
    },
    actionComplete(e) {
      if (e.requestType === 'searching') return this.searchInSyncfusionGrid(e)
    },
    searchInSyncfusionGrid(e) {
      this.listData = this.persistedData.filter((item) => item[this.searchBy]?.toString().includes(e.searchString) ?? true)
    },
    selectDropdownHandler(opt) {
      this.searchBy = opt.value
    },
    setSearchOptions() {
      const searchWrapper = this.$refs.grid.$el.querySelector('.e-toolbar-right')
      const dropdown = this.$refs.selectOption.$el
      searchWrapper.prepend(dropdown)

      searchWrapper.style.display = 'flex'
      searchWrapper.style.gap = '1em'
      dropdown.querySelector('button').style.border = 'none'
    }
  }
}
</script>

<template>
  <LoadingVue v-if="isLoading" />
  <div class="btn_area">
    <AddBtn @click="addLab()"></AddBtn>
  </div>

  <Dropdown
    class="select-wrapper"
    :options="searchConditions"
    :disabled="false"
    name="zipcode"
    ref="selectOption"
    @selected="selectDropdownHandler"
  ></Dropdown>

  <div class="">
    <ejs-grid
      :dataSource="listData"
      :allowPaging="true"
      :allowSorting="true"
      :pageSettings="pageSettings"
      :commandClick="commandClick"
      :toolbar="toolbar"
      :actionComplete="actionComplete"
      ref="grid"
    >
      <e-columns>
        <e-column field="labNo" headerText="연구실번호" width="100" textAlign="center" />
        <e-column field="labName" headerText="연구실이름" width="150" textAlign="center" />
        <e-column field="leadResearcherName" headerText="책임연구자" width="120" textAlign="center" />
        <e-column field="managerUserId" headerText="관리자ID" width="130" textAlign="center" />
        <e-column field="updatedAt" headerText="수정일" width="130" :format="format" type="date" textAlign="center" />
        <e-column field="manage" headerText="관리" width="200" textAlign="center" :commands="commands" />
      </e-columns>
    </ejs-grid>
  </div>
</template>

<style scoped></style>
